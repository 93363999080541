/* eslint-disable no-useless-escape */
import { Row, Form,  Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "../../axiosApi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../actions/departmentActions";
import Google from "./SocialLogin/Google";
import Facebook from "./SocialLogin/Facebook";
const SignUp = () => {
  const [provider, setProvider] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [age, setAge] = useState("");
  const [education, setEducation] = useState("");
  const [email, setEmail] = useState("");
  const [companyEmail, setComapnyEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [profile, setProfile] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [socialId, setsocialId] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { departments } = useSelector((state) => state.department);
  const { isAuthenticated, user } = useSelector((state) => state.admin);
  
  const [validation, setValidation] = useState({
    name: "",
    surname: "",
    age: "",
    education: "",
    email: "",
    department:""
  });

  const checkValidations = (e) => {
    const rege = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    let errors = validation;
    if (
      socialId === null ||
      name === "" ||
      surname === "" ||
      age === "" ||
      age <= 0 ||
      age >= 100 ||
      education === "" ||
      department === "" ||
      email === "" ||
      companyEmail === "" ||
      phone === "" ||
      !rege.test(email) ||
      !rege.test(companyEmail) ||
      phone.length !== 10
    ) {
      errors = {
        name: name === "" ? "Name is required" : "",
        surname: surname === "" ? "Name is required" : "",
        age:
          age === ""
            ? "Age is required"
            : age <= 0 || age >= 100
              ? "Invalid Age"
              : "",
        education: education === "" ? "Education is required" : "",
        department: department === "" ? "Department is required" : "",
        email: email === "" ? "Email is required" : !rege.test(email) ? "Invalid Email" : "",
        companyEmail: companyEmail === "" ? "Company Email is required" : !rege.test(companyEmail) ? "Invalid Email" : "",
        phone:
          phone === ""
            ? "Phone is required"
            : phone.length !== 10
              ? "Invalid Phone"
              : "",
      };
      setIsFormValid(false);
      setValidation(errors);
      return 0
    } else {
      setIsFormValid(true);
      setValidation({
        name: "",
        surname: "",
        age: "",
        education: "",
        email: "",
        companyEmail: "",
        department: "",
        phone: "",
      });
      return 1
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      if (user?.user_role?.roleId === 1 || user?.user_role?.roleId === 2) {
        navigate("/admin");
      }
      else {
        navigate("/user");
      }
    }
  }, [isAuthenticated, navigate, user]);
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid=await checkValidations();

    if (valid || isFormValid) {
      setLoader(true)
      const userData = {
        name,
        surname,
        age,
        education,
        email,
        companyEmail,
        department,
        socialId: socialId,
        phone,
        loginType: provider,
      };
      axios
        .post("/auth/signUp", userData)
        .then((res) => {
          toast.success("SignUp Success, Please Verify your Email");
          navigate("/verify");
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
          toast.error(error.response?.data?.message);
        });
    }
  };
  const handleResolve = (name, data) => {

    setProvider(name);
    setProfile(data);
    setEmail(data?.email);

    if (name === "google") {
      setName(data?.given_name);
      setSurname(data?.family_name);
      setsocialId(data?.sub);
    }
    else {
      setName(data?.first_name);
      setSurname(data?.last_name);
      setsocialId(data?.id);
    }

  };

  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    document.body.className = "login-page";
    return () => {
      document.body.className = "";
    };
  });

  return (
    <div className="signUp-box">
      <div className="login-logo" >
        <b>IAMOPS</b>
      </div>

      {profile ? (
        <div className="main-card">
          <div className="card-body login-card-body">

            <p className="signup-box-msg">Please enter details to Signup</p>
            <Form className="form-signin" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <div className="col-md-6">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.name}</p>
                </div>
                <div className="col-md-6">
                  <Form.Label>Surname*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Surname"
                    value={surname}
                    onChange={(e) => {
                      setSurname(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.surname}</p>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-md-6">
                  <Form.Label>Age*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter age"
                    value={age}
                    onChange={(e) => {
                      setAge(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.age}</p>
                </div>

                <div className="col-md-6">
                  <Form.Label>Education*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Education"
                    value={education}
                    onChange={(e) => {
                      setEducation(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.education}</p>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-md-6">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="email"
                    placeholder="Enter personal email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.email}</p>
                </div>

                <div className="col-md-6">
                  <Form.Label>Company email*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter company email"
                    value={companyEmail}
                    onChange={(e) => {
                      setComapnyEmail(e.target.value);
                      checkValidations();
                    }}
                  />
                  <p className="error">{validation?.companyEmail}</p>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-md-6">
                  <Form.Label>Phone*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone"
                    value={phone}
                    onChange={async (e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  <p className="error">{validation?.phone}</p>
                </div>

                <div className="col-md-6">
                  <Form.Label>Department*</Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Enter company email"
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      checkValidations();
                    }}
                  >
                     <option value="" key="">Select Department</option>
                    {departments.map((x, y) => (
                      <option value={x.id} key={x.id}>{x.name}</option>
                    ))}
                   
                  </Form.Select>
                  <p className="error">{validation?.department}</p>
                </div>
              </Row>
              <Row>
              <div className="col-md-12">
                <Button
                  size="lg"
                  className="btn btn-primary btn-lg loginbtn"
                  type="submit"
                  disabled={loader ? true : false}
                >
                  Sign Up

                </Button>
                </div>
              </Row>
            </Form>
            <p className="login-box-note">Already have an account? <Link to="/signIn">Sign In</Link></p>
          </div>
        </div>
      ) : (
        <div className="main-card">
          <div className="card-body login-card-body">
            <h4 className="login-title">Registration</h4>
            <p className="login-box-msg">Please select how would you like to sign up to <strong>IAMOPS</strong></p>
            <div className="login-card">

              <Google handleResolve={handleResolve} text="Sign Up with Google" />
              <Facebook handleResolve={handleResolve} text="Sign Up with Facebook" />
            </div>
            <p className="login-box-note">Already have an account? <Link to="/signIn">Sign In</Link></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
