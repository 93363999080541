import { configureStore } from "@reduxjs/toolkit";
import { addUser, adminSlice, userProfileSlice, userSlice } from "./reducers/adminReducer";
import { departmentSlice } from "./reducers/departmentReducer";


const store = configureStore({
    reducer: {
        admin: adminSlice.reducer,
        users: userSlice.reducer,
        createUser: addUser.reducer,
        userProfile: userProfileSlice.reducer,
        department: departmentSlice.reducer,
    }
});

export default store;