import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        loading: true,
        isAuthenticated: false,
        user: {},
        success:false
    },
    reducers: {
        userRequest(state) {
            state.loading = true;
            state.isAuthenticated = false;
            state.success = false;
        },
        loginRegisterSuccess(state, actions) {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = actions.payload.data;
           
            localStorage.setItem("token", actions.payload.data.token)
        },
        userFail(state, actions) {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.error = actions.payload;
        },
        request(state, actions) {state.success = false;},
        clear(state, actions) {state.success = false;},
        updateSuccess(state, actions) {
            state.success = true;
        },
        success(state, actions) {
            state.success = true;
            state.user = actions.payload.data;
        },
        loadSuccess(state, actions) {
            state.loading = false;
            state.user = actions.payload.data;
            state.isAuthenticated = true;
        },
        logout(state) {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            localStorage.removeItem("token");
        },
    }
});
export const userSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        users: [],
        totalRows: 0,
    },
    reducers:{
        userRequest(state){
            state.loading=true;
            state.users=[]; 
            state.totalRows=0;
        },
        userSuccess(state,actions){
            state.loading=false;
            state.users=actions.payload.data; 
            state.totalRows=actions.payload.total
        },
        userFail(state,actions){
            state.loading=false;
            state.error=actions.payload; 
        },
    }
});
export const addUser = createSlice({
    name: 'addUser',
    initialState: {
        loading: false,
        success: false,
    },
    reducers:{
        request(state){
            state.loading=true;
            state.success=false;
        },
        success(state,actions){
            state.loading=false;
            state.success=true;
        },
        clear(state,actions){
            state.loading=false;
            state.success=false;
        },
    }
});
export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
        loading: false,
        userData: {},
    },
    reducers:{
        request(state){
            state.loading=true;
            state.userData={};
        },
        success(state,actions){
            state.loading=false;
            state.userData=actions.payload.data;
        },
        fail(state,actions){
            state.loading=false;
            state.userData={};
        },
    }
});


export const adminActions = adminSlice.actions;
export const userActions = userSlice.actions;
export const createUserActions = addUser.actions;
export const userProfileActions = userProfileSlice.actions;

