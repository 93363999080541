import React, { useEffect } from "react";
import { getUserProfile } from "../../../actions/adminActions";
import Sidebar from "../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Row } from "react-bootstrap";

const View = () => {
  const { userData } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(getUserProfile(params.id));
  }, [dispatch, params.id]);

  return (
    <div className="single">
      <Sidebar />
      <div className="content-wrapper" style={{ minHeight: "1302.12px" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="ml-2">View User</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Users</Link>
                  </li>
                  <li className="breadcrumb-item active">View</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <Form className="f" >
                      <Row className="mb-3">
                        <div className="col-md-4">
                          <Form.Label>Name</Form.Label>
                          <p>{userData?.name}</p>
                        </div>

                        <div className="col-md-4">
                          <Form.Label>Surname</Form.Label>
                          <p>{userData?.surname}</p>
                        </div>


                        <div className="col-md-4">
                          <Form.Label>Age</Form.Label>
                          <p>{userData?.age}</p>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-4">
                          <Form.Label>Education</Form.Label>
                          <p>{userData?.education}</p>
                        </div>

                        <div className="col-md-4">
                          <Form.Label>Email</Form.Label>
                          <p>{userData?.email}</p>
                        </div>

                        <div className="col-md-4">
                          <Form.Label>Company email</Form.Label>
                          <p>{userData?.companyEmail}</p>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-4">
                          <Form.Label>Phone</Form.Label>
                          <p>{userData?.phone}</p>
                        </div>

                        <div className="col-md-4">
                          <Form.Label>Department</Form.Label>
                          <p className="">{userData?.department?.name}</p>
                        </div>

                        <div className="col-md-4 user-block">
                          <Form.Label>Profile</Form.Label>
                          <p>
                            <img className={`img-circle img-bordered-md ${userData?.profile ? "" : "d-none"}`} src={userData?.profile} alt="user" /></p>
                        </div>
                      </Row>
                      <Row >
                        <div className="col-md-4">
                          <Link
                            to="/admin/users"
                            className="btn btn-danger"
                          >
                            Cancel
                          </Link>
                        </div>
                      </Row>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default View;
