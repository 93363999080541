import SignUp from './components/Auth/SignUp';
import SignIn from './components/Auth/SignIn';
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { InternProtectedRoute, AdminProtectedRoute, CommonAdminProtectedRoute } from './components/ProtectedRoutes'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from './actions/adminActions';
import Create from './components/Admin/Users/Create';
import List from './components/Admin/Users/List';
import View from './components/Admin/Users/View';
import NotFound from './components/NotFound';
import Profile from './components/Profile/Profile';
import { Dashboard } from './components/User/Dashboard';
import Verify from './components/User/Verify';
import { adminActions } from './reducers/adminReducer';


const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const admintoken = localStorage.getItem("token");
    if (admintoken) {
      dispatch(getProfile());
    }
    else{
      dispatch(adminActions.userFail());
    }

  }, [dispatch]);

  return (
    <div className="wrapper">
      <ToastContainer />
      <Routes>

        <Route path="/" element={<SignIn />}></Route>
        <Route path="/signIn" element={<SignIn />}></Route>
        <Route path="/signUp" element={<SignUp />}></Route>
        <Route path="/verify/:token/:id" element={<Verify />}></Route>
        <Route path="/verify" element={<Verify />}></Route>

        <Route path="admin" element={<CommonAdminProtectedRoute />}>
          <Route exact="true" path="" element={<Dashboard />}></Route>
          <Route path="users" element={<List />}></Route>
          <Route path="user/:id" element={<View />}></Route>
          
          <Route path="profile" element={<Profile />}></Route>
          <Route path="user" element={<AdminProtectedRoute />}>
            <Route path="create" element={<Create />}></Route>
            <Route path=":id/update" element={<Profile />}></Route>
          </Route>
        </Route>
        <Route path="user" element={<InternProtectedRoute />}>
          <Route path="" element={<Dashboard />}></Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
