import axiosApi from '../axiosApi';
import { departmentActions } from '../reducers/departmentReducer';
export const getDepartments = () => async (dispatch) => {
    try {
        dispatch(departmentActions.request());
        const { data } = await axiosApi.get(`/departments`);
        dispatch(departmentActions.success(data))
    }
    catch (error) {
        dispatch(departmentActions.fail(error.response.data.message));
    }
}