import axiosApi from '../axiosApi';
import { toast } from 'react-toastify';
import { adminActions } from '../reducers/adminReducer';
export const login = (userData) => async (dispatch) => {
    try {
        dispatch(adminActions.userRequest());
        const { data } = await axiosApi.post(`/auth/signIn`, userData);
        toast.success("Login Success, Login to Continue",{autoClose: 15000});
        dispatch(adminActions.loginRegisterSuccess(data))
    }
    catch (error) {
        toast.error(error.response?.data?.message);
        dispatch(adminActions.userFail(error.response.data.message));
    }
}