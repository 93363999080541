import React, { Fragment } from 'react'

import Sidebar from '../Admin/sidebar/Sidebar';

export const Dashboard = () => {

  return (
    <Fragment>
      <Sidebar />
      <div className="content-wrapper" style={{ minHeight: "1302.12px" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/admin">Home</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-outline">
                <div className="card-body text-center">
                  <b>Welcome to IAMOPS</b>
                </div>
              </div>
            </div>

          </div>


        </section>
      </div>
    </Fragment>
  )
}
