import { Link } from "react-router-dom";
import { adminActions } from "../../../reducers/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
const Sidebar = () => {
  const dispatch = useDispatch();
  const [open,setOpen]=useState(true)
  const handleClick = () => {
    dispatch(adminActions.logout());
  };
  const { user } = useSelector((state) => state.admin);
  const pathname = useLocation().pathname

  return (
    <Fragment>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="pushmenu"
              // onClick={openside}
              role="button"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </li>
        </ul>
      </nav>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/admin" className="brand-link text-center text-decoration-none">
          <span className="brand-text font-weight-light">{ user && (user.user_role?.roleId === 1 || user.user_role?.roleId === 2) ? "Admin IAMOPS":"IAMOPS"}</span>
        </Link>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">

              <img
                src={user.profile ? user.profile : "/user.jpg"}
                className="img-circle elevation-2"
                alt="User "
              />
            </div>
            <div className="info">
            
              <Link to={user.user_role?.roleId === 1?"/admin/profile":"/"} className="d-block text-decoration-none">
                {user.name} {user.surname}  { user && (user.user_role?.roleId === 1) ? 
                <i className="fas fa-edit" style={{ marginLeft: "8px" }}></i>:""}
              </Link>
            
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {user && (user.user_role?.roleId === 1 || user.user_role?.roleId === 2) ? (
                <li className="nav-item ">
                  <Link
                    to="/admin/users"
                    className={`nav-link ${pathname==="/admin/users"?"active":""}`}
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fas fa-user-alt"></i>
                    <p style={{ marginLeft: "10px" }}>Users</p>
                  </Link>
                </li>
              ) : ""}
              <li className="nav-item" onClick={() => handleClick()}>
                <Link className="nav-link" style={{ textDecoration: "none" }}>
                  <i className="fas fa-times"></i>
                  <p style={{ marginLeft: "10px" }}>Logout</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </Fragment>
  );
};

export default Sidebar;
