import { createSlice } from "@reduxjs/toolkit";

export const departmentSlice = createSlice({
    name: 'department',
    initialState: {
        loading: false,
        departments: [],
    },
    reducers: {
        request(state) {
            state.loading = true;
        },
        success(state, actions) {
            state.loading = false;
            state.departments = actions.payload.data;
        },
        fail(state, actions) {
            state.loading = false;
            state.departments = [];
        },
    }
});

export const departmentActions = departmentSlice.actions;

