import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row } from "react-bootstrap";
import Sidebar from '../Admin/sidebar/Sidebar';
import { getUserProfile, updateProfile } from '../../actions/adminActions';
import { getDepartments } from '../../actions/departmentActions';
import { toast } from 'react-toastify';
import { adminActions } from '../../reducers/adminReducer';

const Profile = () => {
    const { user, success } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.userProfile);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [age, setAge] = useState("");
    const [education, setEducation] = useState("");
    const [email, setEmail] = useState("");
    const [companyEmail, setComapnyEmail] = useState("");
    const [department, setDepartment] = useState("");
    const [phone, setPhone] = useState("");
    const [profile, setProfile] = useState(null);
    const [preview, setPreview] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();
    const { departments } = useSelector((state) => state.department);
    const [validation, setValidation] = useState({
        name: "",
        surname: "",
        age: "",
        education: "",
        email: "",
        phone: "",
    });
    const params = useParams();
    useEffect(() => {
        let data = null;

        if (params.id) {
            data = userData;

        }
        else {
            data = user;
        }
        setName(data?.name?data?.name:"")
        setSurname(data?.surname?data?.surname:"")
        setAge(data?.age?data?.age:"")
        setEducation(data?.education?data?.education:"")
        setEmail(data?.email?data?.email:"")
        setComapnyEmail(data?.companyEmail?data?.companyEmail:"")
        setDepartment(data?.departmentId?data?.departmentId:"")
        setPhone(data?.phone?data?.phone:"")
        setPreview(data?.profile?data?.profile:"")

    }, [params.id, user, userData])
    useEffect(() => {
        if (success) {
            toast.success("Update Successfull");
            navigate("/admin");
            dispatch(adminActions.clear())
        }
    }, [success, navigate, dispatch]);

    const handleChange = (event) => {
        checkValidations();
        setPreview(URL.createObjectURL(event.target.files[0]))
        setProfile(event.target.files[0]);
    };

    useEffect(() => {
        dispatch(getDepartments());
        if (params.id) {
            dispatch(getUserProfile(params.id));
        }
        dispatch(adminActions.clear())
    }, [dispatch, params.id]);

    const checkValidations = (e) => {
        let errors = validation;
        if (
            name === "" ||
            surname === "" ||
            age === "" ||
            age <= 0 ||
            age >= 100 ||
            education === "" ||
            email === "" ||
            companyEmail === "" ||
            department === "" ||
            phone === ""
        ) {
            errors = {
                name: name === "" ? "Name is required" : "",
                surname: surname === "" ? "Surname is required" : "",
                age:
                    age === ""
                        ? "Age is required"
                        : age <= 0 || age >= 100
                            ? "Invalid Age"
                            : "",
                education: education === "" ? "Education is required" : "",
                email: email === "" ? "Email is required" : "",
                companyEmail: companyEmail === "" ? "Company Email is required" : "",
                department: department === "" ? "Department is required" : "",
                phone: phone === "" ? "Phone is required" : "",
            };
            setIsFormValid(false);
            setValidation(errors);
            return 0;
        } else {
            setValidation({
                name: "",
                surname: "",
                age: "",
                education: "",
                email: "",
                phone: "",
                companyEmail: "",
                department: "",
            });
            setIsFormValid(true);
            return 1;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const valid=await checkValidations();
        if (valid || isFormValid) {
            let review = {
                name,
                surname,
                age,
                education,
                email,
                companyEmail,
                department,
                phone,
                profile,
            };
            const formData = new FormData();

            for (var key in review) {
                formData.append(key, review[key]);
            }
            if (params.id) {
                formData.append("id", params.id);
                await dispatch(updateProfile(formData, "user"));
            }
            else {
                await dispatch(updateProfile(formData, "profile"));
            }
        }
    };
    return (
        <div className="single">
            <Sidebar />
            <div className="content-wrapper" style={{ minHeight: "1302.12px" }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h4 className="ml-2">Edit Profile</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin">Admin</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form className="form-signin" onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Name*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter name"
                                                        value={name}
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.name}</p>
                                                </div>

                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Surname*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Surname"
                                                        value={surname}
                                                        onChange={(e) => {
                                                            setSurname(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.surname}</p>
                                                </div>


                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Age*</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter age"
                                                        value={age}
                                                        onChange={(e) => {
                                                            setAge(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.age}</p>
                                                </div>
                                           
                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Education*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Education"
                                                        value={education}
                                                        onChange={(e) => {
                                                            setEducation(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.education}</p>
                                                </div>

                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Email*</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter personal email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.email}</p>
                                                </div>

                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Company email*</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter company email"
                                                        value={companyEmail}
                                                        onChange={(e) => {
                                                            setComapnyEmail(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.companyEmail}</p>
                                                </div>
                                            
                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Phone*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Phone"
                                                        value={phone}
                                                        onChange={(e) => {
                                                            setPhone(e.target.value);
                                                            checkValidations();
                                                        }}
                                                    />
                                                    <p className="error">{validation?.phone}</p>
                                                </div>

                                                <div className="col-md-4 form-group">
                                                    <Form.Label>Department*</Form.Label>
                                                    <Form.Select
                                                        type="text"
                                                        placeholder="Enter company email"
                                                        value={department}
                                                        onChange={(e) => {
                                                            setDepartment(e.target.value)
                                                            checkValidations();
                                                        }}
                                                    >
                                                        <option key="">Select Department</option>
                                                        {departments.map((x, y) => (
                                                            <option value={x.id} key={x.id}>{x.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <p className="error">{validation?.department}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <Form.Label>Profile</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        placeholder="Enter "
                                                        onChange={handleChange}
                                                    />


                                                </div>
                                                <div className="col-md-1 user-block">
                                                    <Form.Label></Form.Label>
                                                    <p>
                                                        <img className={`img-circle img-bordered-md profile-img ${!preview || preview===""?"d-none":""}`} src={preview} alt="user" /></p>
                                                </div>
                                            </Row>
                                            <Row >
                                                <div className="col">
                                                    <Button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </Button>
                                                    <Link
                                                        to={`${params.id?"/admin/users":"/admin"}`}
                                                        className="btn btn-danger ml-2"
                                                    >
                                                        Cancel
                                                    </Link>
                                                    
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Profile