import DataTable from "react-data-table-component";

const Datatable = ({ columns, data, title, loading, totalRows, handlePageChange, handlePerRowsChange }) => {

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      defaultSortFieldId={1}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

export default Datatable;
