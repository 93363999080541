import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export const AdminProtectedRoute = ({type}) => {
    
    const { loading, isAuthenticated ,user} = useSelector(state => state.admin);
    return (
        <>
            {
                !loading && (
                    isAuthenticated && (user?.user_role?.roleId===1) ? <Outlet /> : <Navigate to="/signIn"/>
                )
            }
        </>
    )
}
export const CommonAdminProtectedRoute = ({type}) => {
    
    const { loading, isAuthenticated ,user} = useSelector(state => state.admin);
    return (
        <>
            {
                !loading && (
                    isAuthenticated && (user?.user_role?.roleId===1 || user?.user_role?.roleId===2) ? <Outlet /> : <Navigate to="/signIn"/>
                )
            }
        </>
    )
}

export const InternProtectedRoute = ({type}) => {
    
    const { loading, isAuthenticated,user } = useSelector(state => state.admin);
    return (
        <>
            {
                !loading && (
                    isAuthenticated  && (user?.user_role?.roleId===3) ?<Outlet />: <Navigate to="/signIn"/>
                )
            }
        </>
    )
}