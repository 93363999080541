import React from 'react'
import { Link } from 'react-router-dom';
import { LoginSocialGoogle } from 'reactjs-social-login';

const Google = ({ handleResolve, text }) => {
    return (
        <LoginSocialGoogle
            client_id={process.env.REACT_APP_GOOGLE_APP_ID || ""}
            onResolve={({ provider, data }) => {
                handleResolve(provider, data);
            }}
            className="btn btn-block btn-google mt-2"
        >
            <Link href="#">
            <i className="fab fa-google mr-2"></i>{text}
            </Link>
        </LoginSocialGoogle>
    )
}

export default Google