import React from 'react'
import { Link } from 'react-router-dom';
import { LoginSocialFacebook } from 'reactjs-social-login';

const Facebook = ({ handleResolve, text }) => {
    return (
        <LoginSocialFacebook
            appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
            fieldsProfile={
                "id,first_name,last_name,middle_name,name,email"
            }
            onResolve={({ provider, data }) => {
                handleResolve(provider, data);
            }}
            className="btn btn-block btn-facebook mt-2"
        >
            <Link>
            <i className="fab fa-facebook mr-2"></i>
               {text}
            </Link>
        </LoginSocialFacebook>
    )
}

export default Facebook