/* eslint-disable no-useless-escape */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/authActions";
import Google from "./SocialLogin/Google";
import Facebook from "./SocialLogin/Facebook";
import { Row, Form, Button } from "react-bootstrap";
import { adminlogin } from "../../actions/adminActions";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleResolve = (name, data) => {
    let userData = {
      email: data.email,
    }
    if (name === "google") {
      userData.socialId = data.sub;
    }
    else {
      userData.socialId = data.id;
    }
    dispatch(login(userData));
  };
  const { isAuthenticated, user } = useSelector((state) => state.admin);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [validation, setValidation] = useState({
    password: "",
    email: "",
  });

  const rege = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  const checkValidations = async(e) => {
    let errors = validation;
    if (

      email === "" ||
      password === "" ||
      !rege.test(email)
    ) {
      errors = {

        password: password === "" ? "Password is required" : "",
        email: email === "" ? "Email is required" : !rege.test(email) ? "Invalid Email" : "",
      };
      await setIsFormValid(false);
      setValidation(errors);
      return 0;
    } else {
      setIsFormValid(true);
      setValidation({
        email: "",
        password: "",
      });
      return 1;
    }
  };
 
  const handleSubmit = async (e) => {
    const valid=await checkValidations();
    e.preventDefault();
    if (valid || isFormValid) {
      dispatch(adminlogin(email, password));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.user_role?.roleId === 1 || user?.user_role?.roleId === 2) {
        navigate("/admin");
      }
      else {
        navigate("/user");
      }
    }
  }, [isAuthenticated, navigate, user]);

  useEffect(() => {
    document.body.className = "login-page";
    return () => {
      document.body.className = "";
    };
  });
  return (
    <div className="signUp-box">
      <div className="login-logo" >
        <b>IAMOPS</b>
      </div>
      <div className="main-card">
        <Form className="form-signin" onSubmit={handleSubmit}>
          <div className="card-body login-card-body">
            <h4 className="login-title mb-3">Login</h4>
            {/* <p className="login-box-msg">Please select how would you like to sign in to <strong>IAMOPS</strong></p> */}

            <Row className="mb-3">
              <div className="col-md-12">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <p className="error">{validation?.email}</p>
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-md-12 ">
                <Form.Label>Password*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <p className="error">{validation?.password}</p>
              </div>


            </Row>
            <div className="login-card">
              <Button
                className="btn btn-block  btn-email"
                type="submit"
              >
                Sign In

              </Button>
              <p className="login-with-text">Or Login with</p>
              <div className="login-with-button">
                <Google handleResolve={handleResolve} text="Google" />
                <Facebook handleResolve={handleResolve} text="Facebook" />
              </div>
            </div>

            <p className="login-box-note">Dont have an account? <Link to="/signUp">Sign Up</Link></p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
