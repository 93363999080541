import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from '../../axiosApi';
import { toast } from 'react-toastify';

const Verify = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [isVerify, setIsVerify] = useState(false);
    const [text, setText] = useState("");
    const [showComplete, setshowComplete] = useState(true);
    const [isregister, setRegister] = useState(false);

    useEffect(() => {
        if (params.token && params.id) {
            setIsVerify(true)
            axios
                .post("/auth/verify", { token: params.token, id: params.id })
                .then((response) => {
                    setIsVerify(true)
                    setText("Verification Complete")
                    toast.success(response?.data?.message);
                })
                .catch((error) => {
                    setText("Invalid User")
                    setshowComplete(false)
                    toast.error(error.response?.data?.message);
                });
        }
        else {
            setRegister(true)
        }
    }, [params.token, params.id, navigate])
    
    useEffect(() => {
        document.body.className = "login-page";
        return () => {
            document.body.className = "";
        };
    });
    return (
        <div className="signUp-box">
            <div className="login-logo" >
                <b>IAMOPS</b>
            </div>
            <div className="main-card">
                <div className="card-body login-card-body">
                    {isregister && (
                        <Fragment>
                            <h4 className="login-title">Registration Complete</h4>

                            <i className="fas fa-check-circle checkbtn"></i>
                            <p className="login-box-msg">Please check your inbox for confirmation email from us</p>
                        </Fragment>
                    )}
                    {isVerify && (
                        <Fragment>
                            <h4 className="login-title">{text}</h4>
                            {showComplete ?
                                <i className="fas fa-check-circle checkbtn"></i>
                                :
                                <i className="fas fa-times checkbtn crossbtn" ></i>
                            }
                           <p className="login-box-msg">Successfully verified your email. Please login now.</p>
                        </Fragment>
                    )}
                    <Link className='btn btn-primary btn-lg loginbtn' to="/signIn">SignIn</Link>
                </div>
            </div>
        </div>
    )
}

export default Verify