import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Datatable from "../../Datatable/Datatable";
import { Link } from "react-router-dom";
import { getUsers } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";

const List = () => {
  const dispatch = useDispatch();
  const userColumns = [
    { selector: (row) => row.id, name: "ID" },
    {
      name: "Name",
      width: 180,
      cell: (params) => {
        return (
          <div className="cellWithImg">
            {params.name} {params.surname}
          </div>
        );
      },
    },
    { selector: (row) => row.email, name: "Email", width: 200 },
    { selector: (row) => row.phone, name: "Phone", width: 200 },
    {
      name: "Department",
      width: 150,
      cell: (params) => params.department?.name,
    },
    {
     
      name: "Role",
      cell: (params) => params.user_role?.role?.name,
    },
  ];
  const { users, loading, totalRows } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.admin);
  const [perPage, setPerPage] = useState(process.env.REACT_APP_PER_PAGE_RECORD );
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getUsers(1, perPage));
  }, [dispatch, perPage]);

  const handlePageChange = page => {
    dispatch(getUsers(page, perPage));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearch(e.target.value)
    dispatch(getUsers(1, perPage,search));
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(getUsers(page, newPerPage));
    setPerPage(newPerPage)
  };

  const actionColumn = [
    {
      name: "Action",
      width: 200,
      cell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/user/${params.id}`}
              style={{ textDecoration: "none" }}
            >
              <button type="button" className="btn btn-primary">
                View
              </button>

            </Link>
            {user && user.user_role?.roleId === 1 ? <Link
              to={`/admin/user/${params.id}/update`}
              style={{ textDecoration: "none" }}
            >
              <button type="button" className="btn ml-2 btn-danger">
                Edit
              </button>
            </Link> : ""}
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Sidebar />
      <div className="content-wrapper" style={{ minHeight: "1302.12px" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="ml-2">Users</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link href="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row my-4">
                      <div className="col-sm-6">
                        <div className="col-sm-12 col-md-6">
                          <div id="example1_filter" className="dataTables_filter">
                            <label>
                              <input type="search"
                              value={search}
                              onChange={handleSearch}
                              className="form-control form-control-sm" placeholder="Search here..." aria-controls="example1" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        {user && user.user_role?.roleId === 1 ? (
                          <Link
                            to="/admin/user/create"
                            type="button"
                            className="btn btn-md btn-success float-sm-right"
                          >
                            Add New User
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <Datatable
                      title=""
                      columns={userColumns.concat(actionColumn)}
                      data={users}
                      handlePageChange={handlePageChange}
                      handlePerRowsChange={handlePerRowsChange}
                      loading={loading}
                      totalRows={totalRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default List;
