import axiosApi from '../axiosApi';
import { adminActions, createUserActions, userActions, userProfileActions } from '../reducers/adminReducer';
import { toast } from 'react-toastify';
export const adminlogin = (email, password) => async (dispatch) => {
    try {
        dispatch(adminActions.userRequest());
        const { data } = await axiosApi.post(`/admin/signIn`, { email, password });
        toast.success("Login Success");
        dispatch(adminActions.loginRegisterSuccess(data))
    }
    catch (error) {
        toast.error(error.response?.data?.message);
        dispatch(adminActions.userFail(error.response.data.message));
    }
}
export const getProfile = () => async (dispatch) => {
    try {
        dispatch(adminActions.userRequest())

        const { data } = await axiosApi.get("/admin/profile");
        dispatch(adminActions.loadSuccess(data))
    }
    catch (error) {
        dispatch(adminActions.userFail());
    }
}
export const updateProfile = (userdata, type) => async (dispatch) => {
    try {
        dispatch(adminActions.request())

        const { data } = await axiosApi.post(`/admin/${type}/update`, userdata);
        if (type === "profile") {
            dispatch(adminActions.success(data))
        }
        else {
            dispatch(adminActions.updateSuccess(data))

        }
    }
    catch (error) {
        dispatch(adminActions.clear());
    }
}

export const getUsers = (page,perPage,search="") => async (dispatch) => {
    try {
        dispatch(userActions.userRequest())

        const { data } = await axiosApi.get(`/admin/getUsers?page=${page}&perPage=${perPage}&search=${search}`);
        dispatch(userActions.userSuccess(data))
    }
    catch (error) {
        dispatch(userActions.userFail());
    }
}
export const addUser = (formData) => async (dispatch) => {
    try {
        dispatch(createUserActions.request())

        await axiosApi.post("/admin/adduser", formData).then(res => {
            toast.success("User Created Successfully");
            dispatch(createUserActions.success())
        }).catch(err => {
            dispatch(createUserActions.clear())
            toast.error(err.response.data.message);
        });
    }
    catch (error) {
        dispatch(createUserActions.clear());
    }
}
export const getUserProfile = (id) => async (dispatch) => {
    try {
        dispatch(userProfileActions.request())
        const { data } = await axiosApi.get(`/admin/user/${id}`)
        dispatch(userProfileActions.success(data))
    }
    catch (error) {
        dispatch(userProfileActions.fail());
    }
}