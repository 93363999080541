import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if(token){
        config.headers.Authorization =  token;
    }
    return config;
});
export default axios;