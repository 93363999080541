import React, { useEffect } from "react";
import Sidebar from "../sidebar/Sidebar";
import { useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../actions/adminActions";
import { getDepartments } from "../../../actions/departmentActions";
import { useDispatch, useSelector } from "react-redux";
import { createUserActions } from "../../../reducers/adminReducer";
import { Link } from "react-router-dom";

const Create = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [age, setAge] = useState("");
  const [education, setEducation] = useState("");
  const [email, setEmail] = useState("");
  const [companyEmail, setComapnyEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userType, setUserType] = useState(1);
  const [profile, setProfile] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const { departments } = useSelector((state) => state.department);
  const { success,loading } = useSelector((state) => state.createUser);
  const [validation, setValidation] = useState({
    name: "",
    surname: "",
    age: "",
    education: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    setProfile(event.target.files[0]);
  };
  const dispatch = useDispatch();
  const checkValidations = (e) => {
    let errors = validation;
    if (
      name === "" ||
      surname === "" ||
      age === "" ||
      age <= 0 ||
      age >= 100 ||
      education === "" ||
      email === "" ||
      companyEmail === "" ||
      department === "" ||
      phone === "" ||
      password===""||
      confirmPassword==="" ||confirmPassword!==password
    ) {
      errors = {
        name: name === "" ? "Name is required" : "",
        surname: surname === "" ? "Surname is required" : "",
        age:
          age === ""
            ? "Age is required"
            : age <= 0 || age >= 100
              ? "Invalid Age"
              : "",
        education: education === "" ? "Education is required" : "",
        email: email === "" ? "Email is required" : "",
        companyEmail: companyEmail === "" ? "Company Email is required" : "",
        department: department === "" ? "Department is required" : "",
        phone: phone === "" ? "Phone is required" : "",
        password: password === "" ? "Password is required" : "",
        confirmPassword: confirmPassword === "" ? "Confirm Password is required" : confirmPassword!==password?"Confirm Password does not match":"",
      };
      setIsFormValid(false);
      setValidation(errors);
      return 0;
    } else {
      setValidation({
        name: "",
        surname: "",
        age: "",
        education: "",
        email: "",
        phone: "",
        password: "",
        companyEmail: "",
        department: "",
        confirmPassword: "",
      });
      setIsFormValid(true);
      return 1;
    }
  };
  useEffect(() => {
    if (success) {
      navigate("/admin");
    }
  }, [success, navigate]);

  useEffect(() => {
    dispatch(createUserActions.clear());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid=await checkValidations();

    if (valid ) {
      let review = {
        name,
        surname,
        age,
        education,
        email,
        companyEmail,
        department,
        phone,
        profile,
        password,
        userType,
      };
      const formData = new FormData();

      for (var key in review) {
        formData.append(key, review[key]);
      }
      await dispatch(addUser(formData));
    }
  };
  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  return (
    <div className="new">
      <Sidebar />
      <div className="content-wrapper" style={{ minHeight: "1302.12px" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="ml-2">Add User</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Users</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <Form className="form-signin" onSubmit={handleSubmit}>
                      <Row className="">
                        <div className="col-md-4 form-group">
                          <Form.Label>Name*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.name}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Surname*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Surname"
                            value={surname}
                            onChange={(e) => {
                              setSurname(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.surname}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Age*</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter age"
                            value={age}
                            onChange={(e) => {
                              setAge(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.age}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Education*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Education"
                            value={education}
                            onChange={(e) => {
                              setEducation(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.education}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Email*</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter personal email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.email}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Company email*</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter company email"
                            value={companyEmail}
                            onChange={(e) => {
                              setComapnyEmail(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.companyEmail}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Phone*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Phone"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.phone}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Department*</Form.Label>
                          <Form.Select
                            type="text"
                            placeholder="Enter company email"
                            value={department}
                            onChange={(e) => {
                              setDepartment(e.target.value);
                            }}
                          >
                            <option key="">Select Department</option>
                            {departments.map((x, y) => (
                              <option value={x.id} key={x.id}>{x.name}</option>
                            ))}
                          </Form.Select>
                          <p className="error">{validation?.department}</p>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>User Type*</Form.Label>
                          <Form.Select
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            <option value={1}>Admin</option>
                            <option value={2}>Manager</option>
                            <option value={3}>Intern</option>
                          </Form.Select>
                        </div>

                        <div className="col-md-4 form-group">
                          <Form.Label>Password*</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.password}</p>
                        </div>
                        <div className="col-md-4 form-group">
                          <Form.Label>Confirm Password*</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                          />
                          <p className="error">{validation?.confirmPassword}</p>
                        </div>
                        <div className="col-md-4 form-group">
                          <Form.Label>Profile</Form.Label>
                          <Form.Control
                            type="file"
                            style={{ marginBottom: "15px" }}
                            placeholder="Enter Password"
                            onChange={handleChange}
                          />
                        </div>
                        <Col></Col>
                      </Row>
                      <Row >
                        <div className="col-md-4 form-group">
                          <Button
                            size="lg"
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading ? true : false}
                          >
                            Submit
                          </Button>
                          <Link
                            to={`/admin/users`}
                            className="btn btn-danger btn-lg ml-2"
                          >
                            Cancel
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Create;
